@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  text-align: center;
  font-family: 'Neue', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Neue';
  src: url('./fonts/neue.ttf') ;
}

.parent {
  display: flex;
  align-items: center;
}

.fade {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s;
}

::selection {
  color: #C0BAA4;
}

::-webkit-scrollbar {
  width: 10px;
  color: #000000;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #615D51;
  height: 10px;
}

.left-side,
.right-side {
  position: fixed;
  top: 0;
  bottom: 0;
  background: #615D51;
}

.left-side {
  left: 0;
}

.right-side {
  right: 0;
}

.carousel {
  display: grid;
  grid-template-areas: "overlay";
  width: 700px;
  height: 200px;
}

.carousel-image {
  grid-area: overlay;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
}

.carousel-image.visible {
  opacity: 1;
}

.login-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.login-popup-inner {
  background: white;
  padding: 20px;
  width: 300px;
  max-width: 80%;
}

.admin-button {
  margin-top: 20px;
  padding: 15px;
  font-size: 20px;
  min-width: 400px;
  background-color: #313639;
  color: #EFEDE5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-button:hover {
  background-color: #5a5a5a;
}